import { useQuery } from '@vue/apollo-composable';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { FragmentType, graphql, useFragment } from '../../gql';
import _ from 'lodash';

export const InventoryItemFragment = graphql(/* GraphQL */ `
  fragment InventoryItem on InventoryItem {
    itemID
    displayName
    rarity
    minecraftID
    damage
    uuid
    count
    lore {
      description
      color
      format
    }
    extra {
      anvilUses
      reforge
      recombobulated
    }
  }
`);

export const InventorySlotFragment = graphql(/* GraphQL */ `
  fragment InventorySlot on InventorySlot {
    item {
      ...InventoryItem
    }
    slot
  }
`);

export const useSkyBlokPlayer = (/*playerName: Ref<string>, cuteName?: Ref<string>*/) => {
  const route = useRoute();
  const router = useRouter();

  const playerName = ref(route.params.playerName as string);

  watch(
    () => route.params.playerName,
    () => {
      playerName.value = route.params.playerName as string;
    },
  );

  const cuteName = ref(route.params.cuteName as string);

  watch(
    () => route.params.cuteName,
    () => {
      cuteName.value = route.params.cuteName as string;
    },
  );

  const { result, loading } = useQuery(
    graphql(/* GraphQL */ `
      query SkyBlockPlayer($playerName: ID!, $cuteName: String) {
        skyBlockPlayer(playerName: $playerName, cuteName: $cuteName) {
          displayName
          playerName
          texture
          uuid
          rank {
            displayName
            plusColor
          }
          status {
            online
            game
            mode
          }
          profile {
            cuteName
            slayer {
              slayerXP
              slayers {
                zombie {
                  ...SlayerDetail
                }
                spider {
                  ...SlayerDetail
                }
                wolf {
                  ...SlayerDetail
                }
                enderman {
                  ...SlayerDetail
                }
                blaze {
                  ...SlayerDetail
                }
                vampire {
                  ...SlayerDetail
                }
              }
            }
            pets {
              amount {
                max
                owned
                progress
              }
              missingPets {
                rarity
                type
                texture
                displayName
                lore {
                  description
                  color
                  format
                }
              }
              ownedPets {
                active
                level {
                  currentLevel
                }
                rarity
                type
                texture
                displayName
                lore {
                  description
                  color
                  format
                }
              }
              petScore {
                current
                max
                progress
              }
              petScoreBonus {
                magicFind
              }
            }
            skills {
              levels {
                taming {
                  xp
                  currentLevel
                  maxLevel
                  xpCurrent
                  xpForNext
                  progress
                  levelCap
                  uncappedLevel
                }
                farming {
                  xp
                  currentLevel
                  maxLevel
                  xpCurrent
                  xpForNext
                  progress
                  levelCap
                  uncappedLevel
                }
                mining {
                  xp
                  currentLevel
                  maxLevel
                  xpCurrent
                  xpForNext
                  progress
                  levelCap
                  uncappedLevel
                }
                combat {
                  xp
                  currentLevel
                  maxLevel
                  xpCurrent
                  xpForNext
                  progress
                  levelCap
                  uncappedLevel
                }
                foraging {
                  xp
                  currentLevel
                  maxLevel
                  xpCurrent
                  xpForNext
                  progress
                  levelCap
                  uncappedLevel
                }
                fishing {
                  xp
                  currentLevel
                  maxLevel
                  xpCurrent
                  xpForNext
                  progress
                  levelCap
                  uncappedLevel
                }
                enchanting {
                  xp
                  currentLevel
                  maxLevel
                  xpCurrent
                  xpForNext
                  progress
                  levelCap
                  uncappedLevel
                }
                alchemy {
                  xp
                  currentLevel
                  maxLevel
                  xpCurrent
                  xpForNext
                  progress
                  levelCap
                  uncappedLevel
                }
                carpentry {
                  xp
                  currentLevel
                  maxLevel
                  xpCurrent
                  xpForNext
                  progress
                  levelCap
                  uncappedLevel
                }
                runecrafting {
                  xp
                  currentLevel
                  maxLevel
                  xpCurrent
                  xpForNext
                  progress
                  levelCap
                  uncappedLevel
                }
                social {
                  xp
                  currentLevel
                  maxLevel
                  xpCurrent
                  xpForNext
                  progress
                  levelCap
                  uncappedLevel
                }
              }
              averageLevel
              averageLevelNoProgress
              totalSkillXP
            }
            money {
              bank
              purse
            }
            accessory {
              maxAccessoryCount
              accessoryCount
              recombobulatedCount
              enrichments {
                amount
                name
              }
              missingAccessories {
                accessories {
                  displayName
                  itemID
                  texture
                  rarity
                  lore {
                    description
                    color
                    format
                  }
                  note
                  ahPrice
                }
                upgrades {
                  displayName
                  itemID
                  texture
                  rarity
                  lore {
                    description
                    color
                    format
                  }
                  note
                  ahPrice
                }
              }
              ownedAccessories {
                active {
                  displayName
                  itemID
                  texture
                  rarity
                  lore {
                    description
                    color
                    format
                  }
                  uuid
                  extra {
                    recombobulated
                    enrichment
                  }
                }
                inactive {
                  displayName
                  itemID
                  texture
                  rarity
                  lore {
                    description
                    color
                    format
                  }
                  uuid
                }
              }
            }
            items {
              armor {
                ...InventorySlot
              }
              equippment {
                ...InventorySlot
              }
              inventory {
                ...InventorySlot
              }
              personalVault {
                ...InventorySlot
              }
              wardrobe {
                ...InventorySlot
              }
              quiver {
                ...InventorySlot
              }
              potionBag {
                ...InventorySlot
              }
              fishingBag {
                ...InventorySlot
              }
              accessoryBag {
                ...InventorySlot
              }
              backpack {
                icon {
                  ...InventoryItem
                }
                inventory {
                  ...InventorySlot
                }
              }
            }
            minion {
              minions {
                itemID
                name
                maxLevel
                levels
                maxUnlockedLevel
                type
              }
              slots {
                currentSlots
                toNextSlot
                costToNextSlot
              }
              missingMinions {
                itemID
                name
                level
                type
                price
                wikiUrl
              }
            }
          }
        }
      }

      fragment SlayerDetail on SlayerDetail {
        kills {
          tier1
          tier2
          tier3
          tier4
          tier5
        }
        level {
          currentLevel
          progress
          xp
          xpForNext
        }
      }
    `),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    {
      playerName,
      cuteName,
    },
    {
      pollInterval: 300 * 1000,
    },
  );

  const mapInventoryFragments = (item: any) => {
    if (!item) return [];
    return item
      .map((item: any) =>
        useFragment(InventorySlotFragment, item as FragmentType<typeof InventorySlotFragment>),
      )
      .map((item: any) => ({
        ...item,
        item: useFragment(
          InventoryItemFragment,
          item.item as FragmentType<typeof InventoryItemFragment>,
        ),
      }));
  };

  const player = computed(() => {
    const player = result.value?.skyBlockPlayer;

    return {
      ...player,
      profile: {
        ...player?.profile,
        items: {
          ...player?.profile?.items,
          armor: mapInventoryFragments(player?.profile?.items.armor),
          equippment: mapInventoryFragments(player?.profile?.items.equippment),
          inventory: mapInventoryFragments(player?.profile?.items.inventory),
          personalVault: mapInventoryFragments(player?.profile?.items.personalVault),
          wardrobe: mapInventoryFragments(player?.profile?.items.wardrobe),
          quiver: mapInventoryFragments(player?.profile?.items.quiver),
          potionBag: mapInventoryFragments(player?.profile?.items.potionBag),
          fishingBag: mapInventoryFragments(player?.profile?.items.fishingBag),
          accessoryBag: mapInventoryFragments(player?.profile?.items.accessoryBag),
          backpack:
            player?.profile?.items.backpack.map((item) => ({
              ...item,
              icon: useFragment(
                InventoryItemFragment,
                item.icon as FragmentType<typeof InventoryItemFragment>,
              ),
              inventory: mapInventoryFragments(item.inventory),
            })) || [],
        },
      },
    };
  });

  watch(
    () => player,
    () => {
      if (cuteName.value === '') {
        router.replace({ params: { cuteName: player.value?.profile?.cuteName } });
      }
    },
    { deep: true },
  );

  return {
    player,
    loading,
  };
};
